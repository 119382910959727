$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};


jQuery(document).on('ready', function() {
    formHandler();
    removeFormAlert();
});

jQuery(window).on('resize scroll', function() {
    if (jQuery('.skills').isInViewport()) {
        setTimeout(function() {
            portfolioProfressHandler();
        }, 400);
    }
});


function portfolioProfressHandler() {
    jQuery(".progress").each(function() {
        var dataProgress = $(this).closest('.skill').data('percentage');

        // $(this).attr("stroke-dashoffset", "245");
        jQuery(this).animate({
            "stroke-dashoffset": dataProgress
        }, 1500)
    })
}

function showDialog(dialog) {
    jQuery('.dialog[data-name="' + dialog + '"]').addClass('active');
    jQuery('body').addClass('overflow');

    bindDialogClose();
}

function bindDialogClose() {
    jQuery('.dialog .dialog-close, .dialog-background').on('click', function(){
        jQuery(this).closest('.dialog').removeClass('active');
        jQuery('body').removeClass('overflow');
    });
}


/**
 *	Check contact form
 */
function formHandler() {
    jQuery('#submit').on('click', function(event){
        event.preventDefault();
        removeFormAlert();

        var name = jQuery('#name').val();
        var email = jQuery('#email').val();
        var telephone = jQuery('#telephone').val();
        var message = jQuery('#message').val();

        var i = 0;

        if(name.match(/^[a-zA-Z-\s]{2,}/)) {
            i++;
        }
        else {
            jQuery('#name').closest('.form-row').addClass('invalid');
        }

        if(email.trim().match(/^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,10}$/)) {
            i++;
        }
        else {
            jQuery('#email').closest('.form-row').addClass('invalid');
        }

        if(telephone.trim().match(/^0[1-9][0-9]{8}$/)) {
            i++;
        }
        else {
            jQuery('#telephone').closest('.form-row').addClass('invalid');
        }

        if(message.length >= 10) {
            i++;
        }
        else {
            jQuery('#message').closest('.form-row').addClass('invalid');
        }

        if(i === 4) {
            jQuery.post("/partials/formHandler.php", jQuery("#contactform").serialize(), function(response) {
                jQuery('.form-success-overlay').addClass('active');
            }); 
            return false;
        }
        else {
            jQuery('#form-error').addClass('active');
            return false;
        }
    });
}

function removeFormAlert() {
    jQuery('#form-error').removeClass('active');

    jQuery('input, textarea').on('click', function() {
        jQuery(this).closest('.form-row').removeClass('invalid');
    });
}
